<template>
    <el-row v-if='searchStr.length>0 && !isNoResult'  style="width:1440px;margin:0 auto;background:#fff;">
        <el-col :span="4" >
            <div >
                <div class="search-title">
                     <span>微观分类</span>
                </div>
                <ul class="search-item-wrap">
                    <li v-for="item in tabList" :key="item.code">
                        <p :class="{'active':selTab==item.code && !item.subItem ,'is-disabled':item.subItem || item.num<=0}" @click="changTab(item.code,'',item.subItem || item.num<=0?true:false)">
                            <span class="search-item-name">{{item.name}}</span>
                            <span v-if='item.num>0' class="search-item-num"> {{"("+item.value+")"}}</span>
                        </p>
                        <ul v-if='item.subItem' class="search-item-sub">
                            <li v-for="sub in item.subItem" :key="sub.code" >
                                <p :class="{'active': selSubTab==sub.code,'is-disabled':sub.num<=0}" @click="changTab(item.code,sub.code,sub.num<=0?true:false)">
                                    <span class="search-item-name">{{sub.name}}</span>
                                    <span v-if='sub.num>0' class="search-item-num"> {{"("+sub.value+")"}}</span>
                                </p>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div>
                <div class="search-title">
                     <span>指标列表</span>
                </div>
                <el-scrollbar  style="height:480px;margin-bottom:10px;" >
                <ul class="search-item-wrap">
                    <li v-for="item in itemList" :key="item.itemId" :title='item.itemName' >
                        <p :class="{'active':selItem.id==item.itemId}" @click="getRegionItemNumListFun(item.itemId)">
                            <span class="search-item-name">{{item.itemName}}</span>
                            <span class="search-item-num"> {{"("+item.num+")"}}</span>
                        </p>
                    </li>
                </ul>
                </el-scrollbar>
                <div v-if="isMore" style="text-align: center; padding:10px;">
                    <el-button type="primary" @click="getItemNumListFun(2)">加载更多</el-button>
                </div>
            </div>
        </el-col>
        <el-col :span="20" style="padding:0 20px 20px 20px;border-left:1px solid #DCDFE6;">
            <div>
                <div class="search-title">
                    <span>范围选择</span>
                </div>
                <div v-loading="loading" >
                    <div class="search-region-wrap clearfix">
                        <span class="item-title fontsize16">地区：</span>
                        <!-- 省级地区 -->
                        <ul class="clearfix">
                            <li v-for="region in regionList?.filter(t=> t.parent=='' || t.parent=='0' )" :key="region.code" :class="{'active':proviceCode==region.code,'is-disabled':region.disabled}" @click='getNextRegionItemNumFun("provice",region.code,region.disabled)'>
                                <span class="search-item-name">{{region.name}}</span>
                                <span class="search-item-num">{{region.itemNum?'('+region.itemNum+')':''}}</span>
                            </li>
                        </ul>
                        <!-- 市级地区 -->
                            <ul class="search-region-sub clearfix">
                            <li v-for="region in regionList?.filter(t=>t.parent==proviceCode && proviceCode!='0' )" :key="region.code" :class="{'active':cityCode==region.code,'is-disabled':region.disabled}" @click='getNextRegionItemNumFun("city",region.code,region.disabled)'>
                                <span class="search-item-name">{{region.name}}</span>
                                <span class="search-item-num">{{region.itemNum?'('+region.itemNum+')':''}}</span>
                            </li>
                        </ul>
                        <!-- 区县级地区 -->
                        <ul class="search-region-sub clearfix">
                            <li v-for="region in regionList?.filter(t=>t.parent==cityCode )" :key="region.code" :class="{'active':districtCode==region.code,'is-disabled':region.disabled}" @click='getNextRegionItemNumFun("district",region.code,region.disabled)'>
                                <span class="search-item-name">{{region.name}}</span>
                                <span class="search-item-num">{{region.itemNum?'('+region.itemNum+')':''}}</span>
                            </li>
                        </ul>
                    </div>
                    <div v-if='selTab=="2"' class="search-region-wrap clearfix">
                        <span class="item-title fontsize16">职位：</span>
                        <!-- 职位 -->
                        <ul class="clearfix">
                            <li v-for="item in posList" :key="item.code" :class="{'active':selPos==item.code}" @click='()=>{selPos=item.code; getItemDataListFun()}'>
                                <span class="search-item-name">{{item.name}}</span>
                                <span class="search-item-num">{{item.num?'('+item.num+')':''}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <div class="search-title">
                    <span>数据列表</span>
                    <!-- 图表 -->
                    <i class="iconfont ec-icon-s-data" :class="{'active':selIconId==2}" @click="changIcon(2)"></i>
                    <!-- 列表 -->
                    <i class='iconfont ec-icon-menu' :class="{'active':selIconId==1}" @click="changIcon(1)"></i>
                </div>
                <el-table v-loading="loading"  v-if="selIconId==1" :data="dataList" stripe style="width: 100%" size='small' table-layout='fixed' :height="dataList?.length>10?440:null">
                   
                    <el-table-column prop="date" label="日期" width="180">
                        <template #default="scope">
                            <span>{{ scope.row.date?parseTimeOfCycle(scope.row.date,selTab=='1'?3:selTab=='2'?5:4):'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column v-if='selTab=="1" || selTab=="2"' :prop="'p'+selItem?.id"   >
                        <template #header>
                                <span>{{itemInfo? itemInfo?.itemName+'('+itemInfo?.itemUnit+')':'实际值'}}</span>
                                <item-favorite v-has='{action:"collect"}' name='collect' :itemId='selItem?.id' :objId='selTab=="2"?selPos:""'  :isChange='isChangeFavorite'></item-favorite>
                        </template>
                        <template #default="scope">
                            <span>{{ scope.row['p'+selItem?.id]?scope.row['p'+selItem?.id]:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column v-if='selTab=="3"' prop="p50000013" >
                        <template #header>
                                <span>{{'本期价格('+itemInfo?.itemUnit+'/'+productInfo?.unit+')'}}</span>
                                <item-favorite v-has='{action:"collect"}' itemId='50000013' :objId='selItem?.id' name='collect'  :isChange='isChangeFavorite'></item-favorite>
                        </template>
                        <template #default="scope">
                            <span>{{ scope.row.p50000013?scope.row.p50000013:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column v-if='selTab=="3"' prop="p50000014"  >
                        <template #header>
                                <span>比上期价格涨跌</span>
                                <item-favorite v-has='{action:"collect"}' itemId='50000014' :objId='selItem?.id' name='collect' :isChange='isChangeFavorite_1'></item-favorite>
                        </template>
                        <template #default="scope">
                            <span>{{ scope.row.p50000014?scope.row.p50000014:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column v-if='selTab=="3"' prop="p50000015"  >
                        <template #header>
                                <span>涨跌幅</span>
                                <item-favorite v-has='{action:"collect"}' itemId='50000015' :objId='selItem?.id' name='collect' :isChange='isChangeFavorite_2'></item-favorite>
                        </template>
                        <template #default="scope">
                            <span>{{ scope.row.p50000015?scope.row.p50000015:'-' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-if="selIconId==2" >
                    <div style="text-align:right;padding-right:20px;">
                        <el-switch
                        v-model="isDarkTheme"
                        active-text="深色模式"
                        inactive-text="" @change='initChart'/>
                        <el-button v-has='{action:"download"}' type="text" style="margin-left:10px;" @click="download('download')" ><i class="iconfont ec-icon-download"></i></el-button>
                    </div>
                    <div id="curve_l" style="height:450px;width:100%;" >
                    </div>
                </div>
            </div>
            <div >
            <div class="search-title">
                <span>元数据</span>
            </div>
            <div v-loading="loading" >
                <div v-if="selTab=='3'">
                    <div class="item-row">
                        <span class="item-title">产品名：</span>
                        <span class="item-info">{{productInfo?.name}}</span>
                    </div>
                    <div class="item-row">
                        <span class="item-title">产品单位：</span>
                        <span class="item-info">{{productInfo?.unit}}</span>
                    </div>
                    <div class="item-row">
                        <span class="item-title">产品规格：</span>
                        <span class="item-info">{{productInfo?.spec}}</span>
                    </div>
                    <div class="item-row">
                        <span class="item-title">产品类别：</span>
                        <span class="item-info">{{productInfo?.proTypeName}}</span>
                    </div>
                    <div class="item-row">
                        <span class="item-title">来源：</span>
                        <span class="item-info">{{productInfo?.source}}</span>
                    </div>
                </div>
                <div class="item-row">
                    <span class="item-title">指标项名：</span>
                    <span class="item-info">{{itemInfo?.itemName}}</span>
                </div>
                <div class="item-row">
                    <span class="item-title">单位：</span>
                    <span class="item-info">{{itemInfo?.itemUnit}}</span>
                </div>
                <div class="item-row">
                    <span class="item-title">更新周期：</span>
                    <span class="item-info">{{itemInfo?.itemCycleName}}</span>
                </div>
                <div class="item-row">
                    <span class="item-title">指标等级：</span>
                    <span class="item-info">{{itemInfo?.itemGradeName}}</span>
                </div>
                <div v-if="selTab=='1' || selTab=='2'"  class="item-row">
                    <span class="item-title">指标来源：</span>
                    <span class="item-info">{{itemInfo?.itemSource}}</span>
                </div>
                <div class="item-row">
                    <span class="item-title">指标描述：</span>
                    <span class="item-info">{{itemInfo?.itemDescr}}</span>
                </div>
                <div class="flex_row">
                    <div class="flex-col">
                        <span class="fontsize20">最大值</span> 
                        <span class="num">{{maxVal}}</span>
                    </div>
                    <div class="flex-col">
                        <span class="fontsize20">最小值</span>
                        <span class="num">{{minVal}}</span>
                        </div>
                    <div class="flex-col">
                        <span class="fontsize20">平均值</span>
                        <span class="num">{{avgVal}}</span>
                    </div>
                    <div class="flex-col">
                        <span class="fontsize20">中位数</span>
                        <span class="num">{{middleVal}}</span>
                        </div>
                </div>
            </div>
            </div>
        </el-col>
    </el-row>
    <search-no-result v-if='searchStr.length<=0 || isNoResult' :search="searchStr" type="microeco"></search-no-result>
</template>
<script>
import SearchNoResult from '@/components/SearchNoResult'
import ItemFavorite from '@/components/ItemFavorite'
import { useRoute} from 'vue-router'
import { ref } from '@vue/reactivity'
import * as echarts from 'echarts'
//import {parseTime,base64ToBlob} from '@/utils'
import {base64ToBlob,parseTimeOfCycle} from '@/utils'
import {getClassItemNumOfMicroEco,getItemNumListOfHouse,getItemNumListOfWork,getProductNumList,getRegionItemNumListOfMicroEco,getRegionItemNumListOfProduct,getPosItemNumList,getHouseDataList,getWorkDataList,getProductDataList,getHouseDataList_chart,getWorkDataList_chart,getProductDataList_chart,getProductInfo,getItemInfoOfMicroEco} from '@/http/ecoApi.js'
import {addUserLog,getUserRightFun} from '@/http/basicsApi.js'
import axios from 'axios'
import { nextTick } from '@vue/runtime-core'
export default {
    components: {
        SearchNoResult,
        ItemFavorite
    },
    setup() {
        const route =useRoute(); //useRoute();//当前路由
        //const router = useRouter()
        let searchStr=ref(route.query['key']?route.query['key']:'')//搜索内容
        let loading=ref(false);//正在加载
        let isNoResult=ref(false);//是否有返回结果
        //根据指标搜索获取宏观、行业、微观、舆情的指标数据量
        let tabList=ref(null);//微观分类的列表
        let selTab=ref('');//选中的分类
        let selSubTab=ref('');//选中的物价分类
        let isChangeFavorite=ref('')//是否显示收藏
        let isChangeFavorite_1=ref('')//物价比上期价格涨跌,是否收藏
        let isChangeFavorite_2=ref('')//物价涨跌幅,是否收藏

        const getItemNumOfClassFun= async()=>{
            isChangeFavorite.value='';
            isChangeFavorite_1.value='';
            isChangeFavorite_2.value='';
            if(searchStr.length<=0){
                return false;
            }
            loading.value=true;
            let params={
                search:searchStr.value
            }
            console.log(params);
            const result= await getClassItemNumOfMicroEco(params);
            console.log(result);
            if( result.code==20000){
                tabList.value=result.data
                isNoResult.value=result.data.num>0?true:false;
                //关键词是否查询到数据
                let filterAry=result.data.filter(t=>t.num>0);
                if(filterAry.length>0){
                    //默认第一个有数据量的分类
                    selTab.value=filterAry[0].code;
                    //子级
                    if(filterAry[0].subItem){
                        selSubTab.value=filterAry[0].subItem.filter(t=>t.num>0)[0].code;
                    }
                    //2、获取相关指标项列表
                    getItemNumListFun(1);
                }
                else{
                    isNoResult.value=true;
                }
            }
            else{
                tabList.value=null;
                loading.value=false;
            }
        }
        //改变微观经济的分类
        const changTab=(code,subCode,isNoClick)=>{
            if(isNoClick){
                return false;
            }
            selTab.value=code;
            selSubTab.value=subCode;
            getItemNumListFun(1);
        }
        //获取指标项列表
        let itemList=ref([]);
        let selItem=ref(null);//选中的指标项
        let pageIndex=ref(1);//指标项列表的页数
        const pageSize=ref(20);//指标项列表的每页数量
        let itemTotal=ref(0);//指标项列表的总数
        let isMore=ref(false);//是否有“加载更多”，默认没有
        const getItemNumListFun= async(type)=>{//type,1:重新加载指标项列表，2：加载更多指标项列表
            console.log(selTab.value);
            if(type==1){
                pageIndex.value=1;
                itemList.value=[];
            }
            else if(type==2){
                pageIndex.value=pageIndex.value+1;
            }
            let result=null;
            if(selTab.value=='1'){//房价
                let params={
                    search:searchStr.value,
                    pageIndex:pageIndex.value,
                    pageSize:pageSize.value
                }
                console.log(params);
                result= await getItemNumListOfHouse(params);
                console.log(result);
                
            }
            else if(selTab.value=='2'){//招聘
                let params={
                    search:searchStr.value,
                    pageIndex:pageIndex.value,
                    pageSize:pageSize.value
                }
                console.log(params);
                result= await getItemNumListOfWork(params);
                console.log(result);
                
            }
            else if(selTab.value=='3'){//物价
                let params={
                    name:searchStr.value,
                    proType:selSubTab.value,
                    pageIndex:pageIndex.value,
                    pageSize:pageSize.value
                }
                console.log(params);
                result= await getProductNumList(params);
                console.log(result);
            }
            if( result.code==20000){
                itemTotal.value=result.data.totalCount;
                itemList.value=[...itemList.value, ...result.data.dataList];
                isMore.value=itemTotal.value<=pageIndex.value *pageSize.value?false:true;
                if(type==1){
                    //默认第一指标项
                    getRegionItemNumListFun(itemList.value[0].itemId);
                }
            }
            else if(type==1){
                itemList.value=[];
            }
            
        }
        //获取地区的指标数据量列表
        let regionList=ref(null);
        let proviceCode=ref('-1')//选中的省级地区编号
        let cityCode=ref('-1')//选中的市级地区编号
        let districtCode=ref('-1')//选中的区县级编号
        let selRegionCode=ref('-1');//选中的地区编号
        let posList=ref(null);//职位列表
        let selPos=ref('');//选中的职位ID
        const getRegionItemNumListFun= async(itemId)=>{
            loading.value=true;
            selItem.value={id:itemId};
            console.log(selItem.value);
            selIconId.value=1;//默认列表显示
            proviceCode.value='-1';
            cityCode.value='-1';
            districtCode.value='-1';
            selRegionCode.value='-1';//选中的地区编号
            selPos.value='';
            let params={
                    ids:itemId
                }
            if(selTab.value=='1'){//房价
                console.log(params);
                
                const result= await getRegionItemNumListOfMicroEco(params);
                console.log(result);
                if( result.code==20000){
                    regionList.value=result.data
                }
                else{
                    regionList.value=null;
                }
                handleRegion();
            }
            else if(selTab.value=='2'){//招聘
                axios.all([getRegionItemNumListOfMicroEco(params), getPosItemNumList(params)])
                .then(axios.spread(function (resp1, resp2) {	// spread是将各请求结果拆分返回，如果不使用改方法，则最后的相应结果为一个数组[resp1, resp2]
                    // 两个请求现在都执行完成
                    console.log(resp1);
                    if(resp1.code==20000){
                        regionList.value=resp1.data;
                    }
                    else{
                        regionList.value=null;
                    }
                    console.log(resp2);
                    if(resp2.code==20000){
                        posList.value=resp2.data;
                    }
                    else{
                        posList.value=null;
                    }
                    if(regionList.value && posList.value){
                        selPos.value=posList.value[0].code;
                        handleRegion();
                    }
                }));
            }
            else if(selTab.value=='3'){//物价
                //console.log(params);
                const result= await getRegionItemNumListOfProduct(params);
                //console.log(result);
                if( result.code==20000){
                    regionList.value=result.data
                }
                else{
                    regionList.value=null;
                }
                handleRegion();
            }
            
        }
       //地区处理并获取指标数据
        const handleRegion=()=>{
            if(regionList.value){
                //循环地区列表，对本地区没有数量和下级没有数量的地区禁用（不能点击）
                regionList.value.filter(t=>t.parent=='' || t.parent=='0').forEach(element => {
                    let childAry=regionList.value.filter(t=>t.parent==element.code && t.parent!='0');
                    //本地区是否有数据量
                    if(element.itemNum>0){
                        if(selRegionCode.value=='-1'){
                            selRegionCode.value=element.code;
                            proviceCode.value=element.code;
                        }
                        element.disabled=false;
                    }
                    else if(childAry.length>0 && childAry.filter(t=>t.itemNum>0).length>0){
                        element.disabled=false;
                    }
                    else{
                        element.disabled=true;
                    }
                    //下级地区是否有数据量
                    childAry.forEach(t=>{
                        let subChildAry=regionList.value.filter(tm=>tm.parent==t.code);
                        if(t.itemNum>0){
                            if(selRegionCode.value=='-1'){
                                selRegionCode.value=t.code;
                                cityCode.value=t.code;
                                proviceCode.value=element.code;
                            }
                            t.disabled=false;
                        }
                        else if(subChildAry.length>0 && subChildAry.filter(t=>t.itemNum>0).length>0){
                            t.disabled=false;
                        }
                        else{
                            t.disabled=true;
                        }
                        ///下下级地区是否有数据量
                        subChildAry.forEach(tm=>{
                            if(tm.itemNum>0){
                                if(selRegionCode.value=='-1'){
                                    selRegionCode.value=tm.code;
                                    districtCode.value=tm.code
                                    cityCode.value=t.code;
                                    proviceCode.value=element.code;
                                }
                                tm.disabled=false;
                            }
                            else{
                                tm.disabled=true;
                            }
                        })
                    })
                });
                getItemDataListFun();
            }
        }
        //获取下级地区指标数据量列表
        
        
        const getNextRegionItemNumFun=(type, regionCode,isdisabled)=>{
            if(isdisabled){
                return false;
            }
            if(type=="provice"){
                proviceCode.value=regionCode;
                cityCode.value='-1';
                districtCode.value='-1';
            }
            else if(type=="city"){
                cityCode.value=regionCode;
                districtCode.value='-1';
            }
            else{
                districtCode.value=regionCode;
            }
            selRegionCode.value=regionCode;
            getItemDataListFun();
        }
        
        //列表和图表的切换
        const changIcon=(id)=>{
            selIconId.value=id;
            isDarkTheme.value=false;
            getItemDataListFun();
        }
        //获取指标项数据列表
        let dataList=ref(null);
        let selIconId=ref(1);//
        let isDarkTheme=ref(false)//图表深色模式
        let chartData=ref(null)//图表数据
        const getItemDataListFun=async()=>{
            console.log(selIconId.value);
            isDarkTheme.value=false;
            chartData.value=null;
            
            //获取指标信息
            getItemInfoFun();
            //获取数据
            let params={};
            if(selTab.value=='1'){//房价
                params={
                    pageIndex: 1,
                    pageSize: 1,
                    itemId:selItem.value.id,
                    regionCode:selRegionCode.value
                }
                if(selIconId.value==1){//列表
                    isChangeFavorite.value=selItem.value.id;
                    const result= await getHouseDataList(params);
                    console.log(result);
                    if( result.code==20000){
                        dataList.value=result.data.dataList
                        analysisOfData(result.data.dataList.map(item=>item['p'+selItem.value.id]).filter(item=>item));
                    }
                    else{
                        dataList.value=null;
                    }
                }
                else{//图表
                    console.log(params);
                    const res= await getHouseDataList_chart(params);
                    console.log(res);
                    if(res.code==20000){
                        chartData.value=res.data;
                        initChart();
                        analysisOfData(res.data.item.itemValue.filter(item=>item));
                    }
                }
            }
            else if(selTab.value=='2'){//招聘
                params={
                    pageIndex: 1,
                    pageSize: 1,
                    itemId:selItem.value.id,
                    regionCode:selRegionCode.value,
                    posId:selPos.value
                }
                if(selIconId.value==1){//列表
                    if(isChangeFavorite.value!=selItem.value.id+'_'+selPos.value){
                        isChangeFavorite.value=selItem.value.id+'_'+selPos.value;
                    }
                    
                    const result= await getWorkDataList(params);
                    console.log(result);
                    if( result.code==20000){
                        dataList.value=result.data.dataList
                        analysisOfData(result.data.dataList.map(item=>item['p'+selItem.value.id]).filter(item=>item));
                    }
                    else{
                        dataList.value=null;
                    }
                }
                else{//图表
                    console.log(params);
                    const res= await getWorkDataList_chart(params);//当前时间地区指标项值
                    console.log(res);
                    if(res.code==20000){
                        chartData.value=res.data;
                        initChart();
                        analysisOfData(res.data.item.itemValue.filter(item=>item));
                    }
                }
            }
            else if(selTab.value=='3'){//物价
                params={
                    pageIndex: 1,
                    pageSize: 1,
                    regionCode:selRegionCode.value,
                    proId:selItem.value.id
                }
                isChangeFavorite.value=selItem.value.id;
                isChangeFavorite_1.value=selItem.value.id;
                isChangeFavorite_2.value=selItem.value.id;        
                //获取产品信息
                getProductInfoFun();
                if(selIconId.value==1){//列表
                    const result= await getProductDataList(params);//当前时间地区指标项值
                    console.log(result);
                    if( result.code==20000){
                        dataList.value=result.data.dataList
                        analysisOfData(result.data.dataList.map(item=>item['p50000013']).filter(item=>item));
                    }
                    else{
                        dataList.value=null;
                    }
                }
                else{//图表
                    console.log(params);
                    const res= await getProductDataList_chart(params);//当前时间地区指标项值
                    console.log(res);
                    if(res.code==20000){
                        chartData.value=res.data;
                        initChart();
                        analysisOfData(res.data.item.map(t=>t.itemValue).filter(item=>item));
                    }
                }
            }
            loading.value=false;
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,'指标查询',JSON.stringify(params));
        }
        //获取指标项信息
        let itemInfo=ref(null);//指标项信息
        const getItemInfoFun= async()=>{
            const result= await getItemInfoOfMicroEco(selTab.value=='3'?"50000013":selItem.value.id);//当前时间地区指标项值
            console.log(result);
            if(result.code==20000){
                itemInfo.value=result.data;
            }
        }
        let productInfo=ref(null);//指标项信息
        const getProductInfoFun= async()=>{
            const result= await getProductInfo(selItem.value.id);//当前时间地区指标项值
            console.log(result);
            if(result.code==20000){
                productInfo.value=result.data;
            }
        }
        //计算数据的最大值，最小值、中位数、平均值
        let maxVal=ref('-');
        let minVal=ref('-');
        let avgVal=ref('-');
        let middleVal=ref('-');
        const analysisOfData=(ary)=>{
            maxVal.value='-';
            minVal.value='-';
            avgVal.value='-';
            middleVal.value='-';
            console.log(ary);
            if(ary.length<=0){
                return false;
            }
            //最大值
             maxVal.value=Math.max(...ary);//es6
            //最小值
            minVal.value=Math.min(...ary);
            //平均值
            //1、先求和
            var sumVal = ary.reduce(function(pre, curr) {
                return pre + curr;
            });
            //2、求个数，平均=和/个数
            avgVal.value=(sumVal/ary.length).toFixed(2);
            
            //中位数
            let newArr = ary.sort(function(a,b){return a-b})
            console.log(ary)
            let len=newArr.length
            console.log(len%2)
            if(len%2===0){
                middleVal.value=((newArr[parseInt(len/2-1)]+newArr[len/2])/2).toFixed(2)
            }else{
                middleVal.value=(newArr[parseInt(len/2)]).toFixed(2)
            }
        }
        //初始化图表
        let myChart=null;
        const initChart=()=>{
            if(myChart) {
                myChart.dispose();//销毁
            }
            nextTick(()=>{
                if(isDarkTheme.value==true){
                     myChart =echarts.init(document.getElementById("curve_l"),'dark');
                }
                else{
                     myChart =echarts.init(document.getElementById("curve_l"));
                }
           
                const colors = ['#5470C6', '#EE6666','#91CC75'];
                let option={};
                if(selTab.value=='3'){
                    option = {
                        color: colors,
                        // backgroundColor:'transparent',
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                            type: 'cross'
                            }
                        },
                        dataZoom: [
                            {
                            type: 'inside',
                            height:20,
                            bottom:5,
                            start: 60,
                            end: 100
                            },
                            {
                            start: 60,
                            end: 100
                            }
                        ],
                        // grid: {
                        //     left: '20%'
                        // },
                        // toolbox: {
                        //     feature: {
                        //     dataView: { show: true, readOnly: false },
                        //     restore: { show: true },
                        //     saveAsImage: { show: true }
                        //     }
                        // },
                        legend: {
                            data: ['本期价格', '比上期价格涨跌', '涨跌幅']
                        },
                        xAxis: [
                        {
                            type: 'category',
                            axisTick: {
                                alignWithLabel: true
                            },
                            // prettier-ignore
                            data: chartData.value.date,
                            axisLabel: {
                                formatter: function (params) {
                                    return parseTimeOfCycle(params,4)
                                }
                            },
                            axisPointer: {
                                label: {
                                    formatter: function (params) {
                                        return parseTimeOfCycle(params.value,4)
                                    }
                                }
                            },
                        }],
                        yAxis: [
                            {
                            type: 'value',
                            name: '本期价格',
                            position: 'left',
                            alignTicks: true,
                            axisLine: {
                                show: true,
                                lineStyle: {
                                color: colors[0]
                                }
                            },
                            axisLabel: {
                                formatter: '{value}'
                            }
                            },
                            {
                            type: 'value',
                            name: '比上期价格涨跌',
                            position: 'left',
                            alignTicks: true,
                            offset: 80,
                            axisLine: {
                                show: true,
                                lineStyle: {
                                color: colors[1]
                                }
                            },
                            axisLabel: {
                                formatter: '{value}'
                            }
                            },
                            {
                            type: 'value',
                            name: '涨跌幅',
                            position: 'right',
                            alignTicks: true,
                            axisLine: {
                                show: true,
                                lineStyle: {
                                color: colors[2]
                                }
                            },
                            axisLabel: {
                                formatter: '{value} %'
                            }
                            }
                        ],
                        series: [
                            {
                            name: '本期价格',
                            type: 'bar',
                            data: chartData.value.item.map(t=>t.itemValue)
                            },
                            {
                            name: '比上期价格涨跌',
                            type: 'bar',
                            yAxisIndex: 1,
                            data:chartData.value.item.map(t=>t.itemIncrementVal)
                            },
                            {
                            name: '涨跌幅',
                            type: 'line',
                            yAxisIndex: 2,
                            data: chartData.value.item.map(t=>t.itemSpeed)
                            }
                        ]
                        };
                }
                else{
                    option = {
                    color: colors,
                    // backgroundColor:'transparent',
                    tooltip: {
                        trigger: 'axis',
                        // axisPointer: {
                        // type: 'cross'
                        // }
                    },
                    legend: {},
                    dataZoom: [
                        {
                        type: 'inside',
                        height:20,
                        bottom:5,
                        start: 60,
                        end: 100
                        },
                        {
                        start: 60,
                        end: 100
                        }
                    ],
                    grid: {
                        top: 50,
                        bottom: 80
                    },
                    xAxis: [
                        {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            onZero: false,
                            
                        },
                        axisLabel: {
                            formatter: function (params) {
                                return parseTimeOfCycle(params,selTab.value=='1'?3:5)
                            }
                        },
                        axisPointer: {
                            label: {
                                formatter: function (params) {
                                    return parseTimeOfCycle(params.value,selTab.value=='1'?3:5)
                                }
                            }
                        },
                        // prettier-ignore
                        data: chartData.value.date
                        },
                    ],
                    yAxis: [
                        {
                        type: 'value'
                        }
                    ],
                    series: [
                        {
                        name: '实际值',
                        type: 'line',
                        smooth: true,
                        emphasis: {
                            focus: 'series'
                        },
                        data: chartData.value.item.itemValue
                        },
                        
                    ]};
                }
                myChart.setOption(option);
            });
        }
        const download=async (btnCode)=>{
            //判断是否有权限
            if(!await getUserRightFun(btnCode,1,route.meta.menuId)){
                return false;
            }
            //获取图表的base64地址
            // var opts = {
            //     type: 'png', // 导出的格式，可选 png, jpeg
            //     pixelRatio: 1,// 导出的图片分辨率比例，默认为 1。
            //     //backgroundColor: string,// 导出的图片背景色，默认使用 option 里的 backgroundColor
            //     //excludeComponents: Array.<string>  // 忽略组件的列表，例如要忽略 toolbox 就是 ['toolbox'],一般也忽略了'toolbox'这栏就够了
            // }
            var resBase64 = myChart.getDataURL();   
            // console.log(resBase64);  
            let blob = base64ToBlob(resBase64); //new Blob([content]);
            const url = window.URL.createObjectURL(blob);            
            const link = document.createElement('a');            
            //console.log(fname);         
            link.href = url;     
            //console.log(url);       
            link.setAttribute('download', "图表.png");            
            document.body.appendChild(link);            
            link.click(); 
            //记录用户操作日志
            let params={};
            if(selTab.value=='1'){//房价
                params={
                    itemId:selItem.value.id,
                    regionCode:selRegionCode.value
                }
            }
            else if(selTab.value=='2'){//招聘
                params={
                    itemId:selItem.value.id,
                    regionCode:selRegionCode.value,
                    posId:selPos.value
                }
            }
            else if(selTab.value=='3'){//物价
                params={
                    regionCode:selRegionCode.value,
                    proId:selItem.value.id
                }
            }
            addUserLog(route.name,route.fullPath,'下载',JSON.stringify(params));
        }

        //加载房价、招聘、物价的指标数据量
        getItemNumOfClassFun();
        //记录用户操作日志
        addUserLog(route.name,route.fullPath,'加载',JSON.stringify({search:searchStr.value}));
        return{
            parseTimeOfCycle,
            searchStr,
            loading,
            isNoResult,
            tabList,
            selTab,
            selSubTab,
            changTab,
            itemList,
            itemInfo,
            productInfo,
            selItem,
            isMore,
            regionList,
            getRegionItemNumListFun,
            proviceCode,
            cityCode,
            districtCode,
            getNextRegionItemNumFun,
            posList,
            selPos,
            getItemDataListFun,
            dataList,
            selIconId,
            changIcon,
            isDarkTheme,
            initChart,
            download,
            maxVal,
            minVal,
            avgVal,
            middleVal,
            isChangeFavorite,
            isChangeFavorite_1,
            isChangeFavorite_2,
            getItemNumListFun
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.is-disabled{
    color:#aaa;
    cursor:inherit !important;
}
.search-item-wrap .search-item-sub{
    margin-left: 20px;
}
.search-item-wrap li p{
    line-height: 20px;
    padding: 5px 20px;
    cursor: pointer;
    margin: 0;
    span{
        display: inline-block;
        vertical-align: middle;
    }
    &:not(.is-disabled):hover{
        background-color: rgba($themeColor, 0.05);
    }
}
.search-item-wrap li p.active{
    background: rgba($themeColor, 0.1);
}
.search-item-num{
    color:#fc5531;
}
.search-item-wrap .search-item-name{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 75%;
    display: inline-block;
}
.search-region-wrap{
    position: relative;
    padding-left: 60px;
    min-height: 120px;
}
.search-region-wrap li{
    float: left;
    margin:0 10px;
    line-height: 30px;
    padding: 0 5px;
    cursor: pointer;
    &.active .search-item-name{
        color:$specialtext-color;
        font-weight: bold;
    }
    &:not(.is-disabled):hover .search-item-name {
        color:$specialtext-color;
    }
}
.search-region-wrap .search-region-sub{
    margin-top: 20px;
    background-color: rgba($themeColor, 0.1);
    border-radius: 2px;
}
.search-title{
    font-weight: 600;
    font-size: 14px;
    border-bottom:2px solid $themeColor;
    margin-bottom: 10px;
    margin-top: 20px;
    span{
        background-color: $themeColor;
        line-height: 30px;
        display: inline-block;
        padding: 0 10px;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        letter-spacing:1px;
        color:#fff;
    }
    i{
        float: right;
        margin-right:5px;
        line-height: 30px;
    }
    i.active{
        color: #3F94FD;
    }
}
.flex_row {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around;
    background-color: rgba($themeColor, 0.1);
    border-radius: 2px;
    padding: 20px 0;
    .flex-col{
        text-align: center;
        .num{
            color: $specialtext-color;
            font-weight: 700;
            font-size: 24px;
            margin-top: 10px;
            display: block;
        }
    }
}
</style>